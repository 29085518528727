<template>
	<van-row class="wrapper">
		<van-col span="6" class="product-logo">
			<van-image :src="product.logo" />
		</van-col>
		<van-col span="12" class="product-info">{{product.name}}</van-col>
		<van-col span="6" class="appliy-link">立即申请 >></van-col>
	</van-row>
</template>

<script>
	import {Image as VanImage, Row, Col} from 'vant'
	export default {
		name: "ProductCard",
		components: {
			[VanImage.name]: VanImage,
			[Row.name]: Row,
			[Col.name]: Col
		},
		props: {
			product: Object
		}
	}
</script>

<style scoped>
	.wrapper {
		background-color: #ffffff;
		height: 200px;
		border-radius: 15px;
		margin: 30px;
		display: flex;
		align-items: center;
		font-size: 24px;
	}
	.product-logo {
		border-right: 1px solid #e3e3e3;
		margin: 15px;
		padding-right: 30px;
	}
	.product-info {
		font-size: 42px;
		padding-left: 10px;
	}
	.appliy-link {
		color: #7232dd;
	}
</style>