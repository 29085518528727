<template>
  <page-layout
    bgColor="#f2f2f2"
    page-name="贷款申请"
    :show-home="false"
    style="height: 108vh"
  >
    <div class="main">
      <template v-if="products && products.length > 0">
        <div v-for="p in products" :key="p.id" @click="selectProduct(p.id)">
          <product-card :product="p"></product-card>
        </div>
      </template>
      <template v-else>
        <van-empty description="暂无产品" />
      </template>
    </div>
    <van-popup
      v-model="popup.show"
      :closeable="false"
      :close-on-click-overlay="false"
      position="bottom"
      round
      class="popup-message"
    >
      {{ popup.message }}
    </van-popup>
  </page-layout>
</template>

<script>
import ApplyApi from "@/api/apply";
import ProductCard from "./components/ProductCard";
import { Popup } from "vant";
export default {
  name: "Index",
  components: {
    ProductCard,
    [Popup.name]: Popup,
  },
  props: {
    orgId: String,
    staffId: String,
    v: String,
  },
  data() {
    return {
      products: [],
      popup: {
        show: false,
        message: "",
      },
    };
  },
  created() {
    if (!this.orgId || !this.staffId || !this.v) {
      this.showPopup("链接无效");
    } else {
      this.queryProduct();
    }
  },
  methods: {
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    queryProduct() {
      ApplyApi.queryProduct({
        orgId: this.orgId,
        staffId: this.staffId,
        v: this.v,
      })
        .then((res) => {
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              this.products.push({
                id: item.productCode,
                logo:
                  "https://jzsy-img.caishuib.com/img/logo/201909/b096a639dfaed8622ae4b64534c642d8.png",
                name: item.productName,
              });
            });
          } else {
            this.showPopup("无可申请产品");
          }
        })
        .catch((res) => {
          this.showPopup(res.msg ? res.msg : "系统异常，请稍后再试！");
        });
    },
    selectProduct(pCode) {
      this.$router.push(
        `/apply/${pCode}?orgId=${this.orgId}&staffId=${this.staffId}&v=${this.v}`
      );
    },
  },
};
</script>

<style scoped>
.main {
  font-size: 24px;
}
.tips {
  margin: 30px 0 0 30px;
  font-size: 24px;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>